import { useEffect, useMemo } from "react";
import { ChildrenStoreArray, TableNode } from "@okopok/components/Table";
import { Table } from "@okopok/components/Table/Table";
import { Button, message } from "antd";
import { MessageInstance } from "antd/es/message/interface";
import { makeAutoObservable, runInAction, transaction } from "mobx";
import { observer } from "mobx-react";
import { PageFrameTitlePortal } from "routing/pageFrame/pageFrameTitlePortal";

import { FullScreenLoader } from "elements/fullScreen/fullScreen";
import { SelectStorable } from "elements/inputs/selectStorable/selectStorable";
import { useSearchParamsStorage } from "elements/useSearchParamsStorage";
import { ForecastSelector } from "features/forecastSelector/forecastSelector";
import { type Column, SimpleTableContext } from "features/tableDebug/simpleTable";
import { useProjectContext } from "models/project/context/projectContext";
import { useFact } from "models/project/fact/fact";
import { useForecast } from "models/project/fact/forecast/forecast";
import { Well } from "models/project/fact/well/well";
import { TreeNode, TreeRoot } from "models/tree/tree";
import { genericTableDataPostprocess, type GenericTableRow, getGenericTableData } from "services/back/genericTable/genegicTableService";

import cn from "./baseReport.module.less";

type ReportOptions = {
  chooseSingleLicenceZone?: boolean;
  chooseSingleLicenceZoneIsImportant?: boolean;
  chooseSingleUsc?: boolean;
  chooseSingleUscIsImportant?: boolean;
  useWellsFromTree?: boolean;
};

class Datum extends TableNode<GenericTableRow, Datum> {
  constructor(private readonly datum: GenericTableRow, parent: Datum | null = null) {
    super(parent, { isExpandedChildren: true });

    this.childrenStore = datum?.children
      ? new ChildrenStoreArray(
          this,
          datum.children.filter((child) => child !== undefined).map((child) => new Datum(child, this))
        )
      : null;
  }

  asDRow(): GenericTableRow {
    return this.datum;
  }
}

class ReportTableModel {
  loadedPath: string | undefined;
  path: string | undefined;
  store: Datum | undefined;
  requestError: string | null = null;
  columns: Column[] = [];

  constructor(
    public readonly forecastId: number | undefined,
    public readonly apiPath: string,
    private readonly message: MessageInstance,
    private readonly storage: { getItem: CallableFunction },
    private readonly options?: ReportOptions
  ) {
    makeAutoObservable(this);

    this.store = new Datum({ children: [] });
    this.columns = [];
  }

  private qString(elements: string[]) {
    return elements.map((e) => `${e}=${this.storage.getItem(e)}`).join("&");
  }

  private getQueryParamsList() {
    const list = [];
    if (this.options?.chooseSingleLicenceZone) {
      if (this.storage.getItem("licence_zone_id") || this.options?.chooseSingleLicenceZoneIsImportant) list.push("licence_zone_id");
    }
    if (this.options?.chooseSingleUsc) {
      if (this.storage.getItem("usc_uuid") || this.options?.chooseSingleLicenceZoneIsImportant) list.push("usc_uuid");
    }

    return list;
  }

  private getPath(well_ids: string): string {
    return (
      this.apiPath +
      "?" +
      this.qString(this.getQueryParamsList()) +
      (this.options?.useWellsFromTree ? "&well_ids=" + well_ids : "") +
      (this.forecastId ? "&scenario_id=" + this.forecastId : "")
    );
  }

  get isLoading() {
    return this.path !== this.loadedPath;
  }

  get isUpdated() {
    return false;
  }

  get isCompleted() {
    return true;
  }

  get isValid() {
    return true;
  }

  private takeData(description: ReturnType<typeof genericTableDataPostprocess>) {
    if (description === null) {
      this.requestError = "Данные получены но структура данных не соответствует ожидаемой";
      return;
    }
    const { data, columns } = description;
    this.store = new Datum({ children: data });
    this.columns = columns;
  }

  public fetch(well_ids: string) {
    this.path = this.getPath(well_ids);
    getGenericTableData(this.path, this.message)
      .then((description) =>
        runInAction(() =>
          transaction(() => {
            this.requestError = null;
            this.loadedPath = this.path;
            this.takeData(description as any);
          })
        )
      )
      .catch((result) =>
        runInAction(() => {
          this.requestError = `Не удалось получить данные ${result.status} ${result.url}`;
        })
      );
  }
}

const SelectWrapper = observer(
  ({
    storage,
    store,
    dataKey,
    placeholder,
    multiple,
  }: {
    storage: { getItem: CallableFunction; setItem: CallableFunction };
    store: any;
    dataKey: string;
    placeholder: string;
    multiple?: boolean;
  }) => {
    return (
      <SelectStorable
        placeholder={placeholder}
        style={{ width: 300, overflow: "hidden" }}
        values={[storage.getItem(dataKey) === "" ? null : storage.getItem(dataKey), undefined]}
        onChange={(v) => {
          storage.setItem(dataKey, v == null ? "" : v);
        }}
        {...(multiple
          ? {
              maxTagCount: 1,
              mode: "multiple",
            }
          : {})}
        store={store}
      />
    );
  }
);

const processTreeSelect = (node: TreeNode<any> | TreeRoot, selectedIds: Set<number>) => {
  if ("item" in node && node.item instanceof Well) {
    if (selectedIds.has(node.item.id)) {
      node.asTableItem.select?.onSelect();
    } else {
      node.asTableItem.select?.onDeselect();
    }
  }
  if ("children" in node && node.children) {
    for (const c of node.children) {
      processTreeSelect(c, selectedIds);
    }
  }
};

const BaseReport = observer(({ apiPath, title, options }: { apiPath: string; title: string; options?: ReportOptions }) => {
  const [messageApi] = message.useMessage();
  const state = useFact()!;
  const fc = useForecast();
  const projectContext = useProjectContext();
  const wellTree = projectContext.wellsTree;

  const storage = useSearchParamsStorage();

  const model = useMemo(
    () => new ReportTableModel(fc?.id, apiPath, messageApi, storage, options),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [apiPath, messageApi, storage, fc, fc?.id, state, state.id, options]
  );

  useEffect(() => {
    if (options?.chooseSingleUsc) storage.setDefaultItem("usc_uuid", null);
    if (options?.chooseSingleLicenceZone) storage.setDefaultItem("licence_zone_id", null);
    if (options?.useWellsFromTree) {
      const wellIdsRaw = storage.getItem("well_ids");
      if (wellIdsRaw && Array.isArray(wellIdsRaw)) {
        const wellIds = new Set(wellIdsRaw);
        processTreeSelect(wellTree, wellIds);
      }
    }
  }, [fc, fc?.id, storage, state, state.id, options, wellTree]);

  useEffect(() => {
    if (options?.useWellsFromTree) {
      const wellIds = wellTree.selectedItems
        .filter((e) => e.item != null && e.item instanceof Well)
        .map((e) => e.item!.id)
        .sort();

      storage.setItem("well_ids", wellIds);
    }
  }, [options?.useWellsFromTree, storage, wellTree.selectedItems]);

  const isScenarioChoosen = fc && fc.id != null;

  const canSubmit = (() => {
    if (options?.chooseSingleUscIsImportant && !storage.getItem("usc_uuid")) return false;
    if (options?.chooseSingleLicenceZoneIsImportant && !storage.getItem("licence_zone_id")) return false;

    return isScenarioChoosen;
  })();

  return (
    <ForecastSelector>
      <SimpleTableContext
        exportFileName={title}
        columns={model.columns}
        data={model.store}
        tableOptions={{
          onRow: ({ indexPath, expand }) => ({
            className: expand === undefined ? cn.tableRowPlain : indexPath.length === 1 ? cn.tableRowPrimary : cn.tableRowSecondary,
          }),
        }}
      >
        <PageFrameTitlePortal>
          {options?.chooseSingleLicenceZone && (
            <SelectWrapper storage={storage} store={state.licenseRegions} placeholder="Лицензионные участки" dataKey="licence_zone_id" />
          )}
          {options?.chooseSingleUsc && <SelectWrapper storage={storage} store={state.ecyStore} placeholder="ЕСУ" dataKey="usc_uuid" />}
          <Button
            onClick={() => {
              model.fetch(
                wellTree.selectedItems
                  .filter((e) => e.item != null && e.item instanceof Well)
                  .map((e) => e.item!.id)
                  .join(",")
              );
            }}
            type="primary"
            disabled={!canSubmit}
          >
            Сформировать отчет
          </Button>
        </PageFrameTitlePortal>
        <div style={{ display: "flex", height: "100%" }}>
          <div style={{ flexGrow: 1 }}>{model.isLoading ? <FullScreenLoader /> : model.columns.length === 0 ? null : <Table />}</div>
        </div>
      </SimpleTableContext>
    </ForecastSelector>
  );
});

export { BaseReport };
