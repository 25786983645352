import { createContext, FC, ReactNode, useContext, useEffect, useMemo } from "react";
import { makeAutoObservable, when } from "mobx";
import { observer } from "mobx-react";

import { SearchParamsStorage, useSearchParamsStorage } from "elements/useSearchParamsStorage";
import { kindInference } from "features/preloader/preloader";
import { Well } from "models/project/fact/well/well";
import { TreeRoot } from "models/tree/tree";

import { Forecast, useForecast } from "../fact/forecast/forecast";
import { Project, useProject } from "../project";

const WELLS_TREE_GROUPING_FIELDS = [
  {
    key: "mine",
    title: "Куст",
    getter: (w: Well) => w.pad,
  },
  {
    key: "licenseRegion",
    title: "Лицензионный участок",
    getter: (w: Well) => w.licenseRegion,
  },
  {
    key: "fond",
    title: "Фонд",
    getter: (w: Well) => w.fond,
  },
  {
    key: "producingObject",
    title: "Объект разработки",
    getter: (w: Well) => w.producingObject,
  },
];

class ProjectData {
  private forecast: Forecast | null | undefined;
  public wellsTree: TreeRoot = new TreeRoot<Well>("Все", [], [], []);
  constructor(public project: Project | null | undefined, public searchParamsStorage: SearchParamsStorage) {
    makeAutoObservable(this);
  }

  setForecast(forecast: Forecast | null | undefined) {
    this.forecast = forecast;
    if (this.forecast !== undefined) {
      when(
        () => {
          const isLoading = (() => {
            if (kindInference(this.project?.fact?.wells)) return true;
            if (kindInference(this.project?.fact?.wellPads)) return true;
            if (kindInference(this.project?.fact?.stratums)) return true;
            if (kindInference(this.project?.fact?.producingObjects)) return true;
            if (kindInference(this.project?.fact?.licenseRegions)) return true;
            if (kindInference(this.forecast)) return true;
            if (this.forecast !== null && kindInference(this.forecast?.wells)) return true;
          })();
          return !isLoading;
        },
        () => {
          let wells: Well[];
          if (this.forecast) {
            wells = this.forecast.wells.allWells;
          } else if (this.project?.fact) {
            wells = this.project.fact.wells.allWells;
          } else {
            wells = [];
          }
          this.wellsTree = new TreeRoot("Все", wells, WELLS_TREE_GROUPING_FIELDS, []);
        }
      );
    }
  }
}

const ProjectDataContext = createContext<ProjectData | null>(null);

const useProjectContext = (): ProjectData => {
  const projectData = useContext(ProjectDataContext);
  console.assert(projectData != null, "запрошен projectData вне контекста ProjectDataContext");
  return projectData!;
};

const ProjectContextProvider: FC<{ children: ReactNode }> = observer(({ children }) => {
  const searchParamsStorage = useSearchParamsStorage();
  const project = useProject();
  const forecast = useForecast();
  const projectData = useMemo(() => {
    return new ProjectData(project, searchParamsStorage);
  }, [project, searchParamsStorage]);

  useEffect(() => {
    projectData.setForecast(forecast);
  }, [forecast, projectData]);

  return <ProjectDataContext.Provider value={projectData}>{children}</ProjectDataContext.Provider>;
});

export { ProjectContextProvider, ProjectData, useProjectContext };
