import { reqCamel } from "utils/request";

import { reqLogged } from "../logger";

import { PipeBoundaryCondition, PipeSystem } from "./types";

async function getPipeSystem(scenarioId: number): Promise<PipeSystem | null> {
  try {
    const response = await reqCamel.get<PipeSystem>(`pipe/${scenarioId}`);
    if (typeof response === "object" && response !== null && "detail" in response) {
      throw Error(response.detail as string);
    }
    return response;
  } catch (error) {
    return null;
  }
}

async function getPipeBoundaryCondition(scenarioId: number): Promise<PipeBoundaryCondition[]> {
  return await reqCamel.get<PipeBoundaryCondition[]>(`pipe_boundary_condition/${scenarioId}`);
}

async function savePipeSystem(scenarioId: number, pipeSystem: PipeSystem): Promise<void> {
  await reqCamel
    .post("pipe/", {
      ...pipeSystem,
      scenarioId,
    })
    .catch(() => {});
}

async function updatePipeBoundaryCondition(scenarioId: number, params: PipeBoundaryCondition[]) {
  await reqLogged.post(`pipe_boundary_condition/${scenarioId}`, params);
}

async function deletePipeSystem(scenarioId: number): Promise<void> {
  await reqCamel.delete(`pipe/${scenarioId}`).catch(() => {});
}

export { deletePipeSystem, getPipeBoundaryCondition, getPipeSystem, savePipeSystem, updatePipeBoundaryCondition };
