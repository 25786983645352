import React, { type FC, useEffect } from "react";
import { Button, Collapse, Radio, Typography } from "antd";
import { observer } from "mobx-react";
import { useParamsModel } from "routing/outlines/params/paramsPage";

import { Format } from "elements/format/format";
import { CompleteIcon } from "elements/icons/complete/complete";
import { InputNumber } from "elements/inputs/inputNumber/inputNumber";
import { Loader } from "elements/loader";
import { UOM } from "elements/uom";
import { EditRowButton } from "features/useMetrics/useMetricsColumns";
import { global } from "models/global";
import { OptionedParam } from "models/params/optionedParams/optionedParam";

import cn from "./scalar.module.less";

const TdShow = (v: number | null, id: number) => (
  <td key={id}>
    <Format>{v}</Format>
  </td>
);

const TdInput = (onUpdate: (value: number | null, id: number) => void, enabled: boolean) => (v: number | null, id: number) =>
  (
    <td key={id}>
      <InputNumber min={0} disabled={!enabled} value={v ?? undefined} onUpdate={(v) => onUpdate(v, id)} />
    </td>
  );

const Table: FC<{ value: OptionedParam }> = observer(({ value }) => {
  const I18N_MAP: Record<string, string> = {
    operations: value.isNet ? "Задать значение" : "Число операций",
    net: "Без инфляции",
    base_index: "Базисный индекс инфляции",
    real: "С учетом инфляции",
  };

  const UOM_MAP: Record<string, UOM | undefined> = {
    operations: value.isNet ? new UOM(2, 3, global.uomResolver) : undefined,
    net: new UOM(2, 3, global.uomResolver),
    base_index: new UOM(3, 1, global.uomResolver),
    real: new UOM(2, 3, global.uomResolver),
  };
  useEffect(() => {
    value.loadTable();
  }, [value]);

  return (
    <div className={cn.table}>
      <table cellSpacing="0" cellPadding="0">
        <thead>
          <tr>
            <th>Параметр</th>
            <th>Ед. изм.</th>
            {[...value.range].map((year) => (
              <th key={year}>{year}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {(["operations", "net", "base_index", "real"] as const).map((key) => {
            if (key === "operations" && !value.isNet && !value.isOperations) {
              return null;
            }
            const val = (() => {
              if (key === "operations") {
                return value.vector;
              }
              if (value.table) {
                return value.table[key];
              }
              return value.table;
            })();
            let row: React.ReactNode;
            if (key === "operations" && (value.isNet || value.isOperations)) {
              row = value.vector.map(TdInput(value.setVectorValue, value.secondary !== null || value.isNet));
            } else if (val === undefined || val === null) {
              row = (
                <td colSpan={value.vector.length}>
                  <Format>{val}</Format>
                </td>
              );
            } else {
              row = val.map(TdShow);
            }
            return (
              <tr key={key}>
                <td className={cn.rowTitle}>
                  {I18N_MAP[key] ?? key}
                  {key === "operations" && <EditRowButton row={value} />}
                </td>
                <td>
                  <Format>{UOM_MAP[key]?.unit ?? null}</Format>
                </td>
                {row}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
});

const CostParam: FC<{ value: OptionedParam }> = observer(({ value }) => {
  useEffect(() => {
    value.prepareAutoCalc();
  }, [value]);

  const INPUT_METHOD_OPTIONS = [
    { label: "Значение на скважину", value: "primary" },
    { label: value.isNet ? "Абсолютное значение" : "На скважино-операцию", value: "secondary" },
  ];

  return (
    <div className={cn.conditionally}>
      <div className={cn.inputs}>
        {(value.isNet || value.secondaryUOM) && (
          <Radio.Group
            className={cn.input}
            options={INPUT_METHOD_OPTIONS}
            onChange={value.setInputMethod}
            value={value.inputMethod}
            optionType="button"
          />
        )}
        <InputNumber
          value={value.primary ?? undefined}
          onUpdate={value.setPrimary}
          className={cn.input}
          addonAfter={
            <>
              {value.primaryUOM.unit}
              <Button
                type="text"
                className={cn.autoCalc}
                disabled={value.autoCalc === undefined || value.autoCalc === value.primary}
                onClick={value.toAutoCalc}
              >
                На основе данных последнего фактического года &nbsp;
                {value.autoCalc !== undefined ? (
                  <>
                    (<Format>{value.autoCalc}</Format> {value.primaryUOM.unit})
                  </>
                ) : (
                  <Format>{undefined}</Format>
                )}
              </Button>
            </>
          }
          bordered
        />
        {value.secondaryUOM && (
          <InputNumber
            placeholder="Для расчета операций по годам"
            value={value.secondary ?? undefined}
            onUpdate={value.setSecondary}
            className={cn.input}
            addonAfter={value.secondaryUOM.unit}
            bordered
          />
        )}
        {value.isNet && <Button onClick={value.fillNetVector}>Заполнить погодовые значения на основе текущего удельного</Button>}
      </div>
      <Collapse className={cn.collapse} size="small" items={[{ key: 1, label: "По годам", children: <Table {...{ value }} /> }]} />
    </div>
  );
});

const OptionedScalar: FC = observer(() => {
  const optionedScalars = useParamsModel().optionedScalars!;
  if (useParamsModel().optionedScalars === undefined) {
    return <Loader />;
  }
  return (
    <div className={cn.scalar}>
      {optionedScalars.grouped.map((group) => {
        const paramMapper = (param: OptionedParam) => (
          <div key={param.id} className={cn.subset}>
            <Typography.Title level={3}>
              <CompleteIcon completed={param.isCompleted} />
              {param.title}
            </Typography.Title>
            <CostParam value={param} />
          </div>
        );
        if ("children" in group) {
          const { title, children } = group;
          return (
            <React.Fragment key={title}>
              <Typography.Title level={2}>{title}</Typography.Title>
              {children?.map(paramMapper)}
            </React.Fragment>
          );
        }
        return paramMapper(group);
      })}
    </div>
  );
});

export { OptionedScalar };
