import { useCallback, useMemo } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { ColumnRaw, TableContextProvider, TableModel, Widget } from "@okopok/components/Table";
import { ExpandButton } from "@okopok/components/Table/widgets/ExpandButton/ExpandButton";
import { Button } from "antd";
import classNames from "classnames";
import { Dayjs } from "dayjs";
import { observer } from "mobx-react-lite";
import { PageFrameTitlePortal } from "routing/pageFrame/pageFrameTitlePortal";

import { DeleteButton } from "elements/deleteButton/deleteButton";
import { Ellipsis } from "elements/ellipsis/ellipsis";
import { Format } from "elements/format/format";
import { LazyInputNumber } from "elements/inputs/lazyInputNumber/lazyInputNumber";
import { MonthPicker } from "elements/inputs/monthPicker";
import { SelectStorable } from "elements/inputs/selectStorable/selectStorable";
import { ToolbarButton } from "elements/toolbarButton/toolbarButton";
import { Tree } from "elements/tree/tree";
import { global } from "models/global";
import { useFact } from "models/project/fact/fact";
import { useForecast } from "models/project/fact/forecast/forecast";
import { type DRow, InputParams } from "models/project/fact/forecast/injPrediction/inputParams";
import { Well } from "models/project/fact/well/well";
import { TreeContextProvider } from "models/tree/context";
import { TreeFilter } from "models/tree/filters/filters";
import { TreeRoot } from "models/tree/tree";

import { usePeriodModal } from "./periodModal/periodModal";
import { ToggleIntersectedButton } from "./toggleInstersectedButton/toggleIntersectedButton";

import cn from "./inputParams.module.less";

const useColumns = () => {
  const fact = useFact()!;
  return useMemo<ColumnRaw<DRow>[]>(() => {
    return [
      {
        key: "index",
        title: "No.пп",
        width: 54,
        isSticky: true,
        render: (_, { absoluteIndex }) => <div style={{ width: "100%", textAlign: "right" }}>{absoluteIndex ?? 0}</div>,
      },
      {
        key: "expand",
        title: <div />,
        isSticky: true,
        width: { min: 32, max: 32, competitiveness: 1 },
        render: (_, tableItem) => (tableItem.expand !== undefined ? <ExpandButton expand={tableItem.expand} /> : <div />),
      },
      {
        dataKey: "wellTitle",
        title: "Скважина",
        isSticky: true,
        width: { min: 100, max: 400, competitiveness: 2 },
        render: (value) => <Format>{value}</Format>,
      },
      {
        dataKey: "mineTitle",
        title: "Куст",
        width: { min: 100, max: 200, competitiveness: 1 },
        render: (value, { expand }) => (expand === undefined ? <Format>{value}</Format> : null),
      },
      {
        dataKey: "eventTitle",
        title: "Мероприятие",
        width: { min: 190, max: 270, competitiveness: 1 },
        render: (value, { expand }) => (expand === undefined ? <Format>{value}</Format> : null),
      },
      {
        dataKey: "licenseRegionTitle",
        title: "ЛУ",
        width: { min: 110, max: 400, competitiveness: 2 },
        render: (value, { expand }) => (expand === undefined ? <Format>{value}</Format> : null),
      },
      {
        dataKey: "start",
        title: "Дата начала",
        width: 130,
        render: (date: Dayjs | undefined, { value, update }) =>
          date && (
            <MonthPicker value={date} onChange={(date) => update?.("start", date)} disabled={value?.periodStatus !== "inj"} variant="borderless" />
          ),
        onCell: ({ value }) => ({
          className: classNames(cn.dateColumn, value?.isIntersected === true && value.periodStatus === "inj" && cn.invalidCell),
        }),
      },
      {
        dataKey: "end",
        title: "Дата окончания",
        width: 130,
        render: (date: Dayjs | undefined, { value, update }) =>
          date && (
            <MonthPicker // TODO: disabled Date
              value={date}
              onChange={(date) => update?.("end", date)}
              disabled={value?.periodStatus !== "inj"}
              variant="borderless"
            />
          ),
        onCell: ({ value }) => ({
          className: classNames(cn.dateColumn, value?.isIntersected === true && value.periodStatus === "inj" && cn.invalidCell),
        }),
      },
      {
        dataKey: "periodStatus",
        title: "Назначение",
        width: 160,
        render: (status?: "prod" | "inj") => status && (status === "prod" ? "Добывающая" : "Нагнетательная"),
      },
      {
        dataKey: "producingObjectId",
        title: "Объект разработки",
        width: 180,
        render: (prodId: number | undefined, { value, update }) =>
          value?.producingObjectsResolver && (
            <SelectStorable
              values={[prodId ?? null, undefined]}
              setValues={(id) => update?.("producingObjectId", id)}
              store={value.producingObjectsResolver}
              disabled={value?.periodStatus !== "inj"}
            />
          ),
      },
      {
        title: "Залежь",
        dataKey: "stratumId",
        width: 180,
        render: (id?: number) => {
          if (id === undefined) {
            return null;
          }
          const stratum = fact.stratums.at(id);
          return (
            <Ellipsis limit={30} position="mid">
              <Format>{id !== null ? stratum?.title : null}</Format>
            </Ellipsis>
          );
        },
      },
      {
        title: "Коэф-т компенсации",
        dataKey: "compensationCoefficient",
        width: 160,
        render: (coef: number | null | undefined, { update, expand }) => {
          if (expand !== undefined || coef === null) {
            return null;
          }
          if (coef === undefined) {
            return <Format>{coef}</Format>;
          }
          return <LazyInputNumber value={coef} onUpdate={(newValue: number | null) => update?.("compensationCoefficient", newValue ?? 0)} min={0} />;
        },
      },
      {
        title: <div />,
        key: "sticky",
        width: 38,
        render: (_, { expand, value }) => {
          if (expand !== undefined || value?.periodStatus !== "inj") {
            return null;
          }
          return <DeleteButton onClick={value?.remove} />;
        },
        onCell: () => ({ style: { position: "sticky", right: 0 } }),
      },
    ];
  }, [fact.stratums]);
};

const TREE_PRELOAD_HOOKS = [
  useFact,
  () => useFact()?.wellPads,
  () => useFact()?.stratums,
  () => useFact()?.producingObjects,
  () => useFact()?.licenseRegions,
];

const TREE_NESTING_FIELDS = [
  {
    key: "mine",
    title: "Куст",
    getter: (w: Well) => w.pad,
  },
  {
    key: "licenseRegion",
    title: "Лицензионный участок",
    getter: (w: Well) => w.licenseRegion,
  },
  {
    key: "fond",
    title: "Фонд",
    getter: (w: Well) => w.fond,
  },
  {
    key: "producingObject",
    title: "Объект разработки",
    getter: (w: Well) => w.producingObject,
  },
];

const useTreeFilters = (): TreeFilter<Well, any>[] => {
  console.assert(global.wellTypes.isLoading === false, "Попытка отображения сводки до завершения загрузки");
  const forecast = useForecast()!;

  return useMemo(
    () => [
      {
        title: "Фонд",
        options: ["Базовый", "Новый"],
        predicateFactory: (selected) => (well: Well) => selected.map((value) => (value === "Базовый" ? "Base" : "New")).includes(well.fond),
      },
      {
        title: "Лицензионные участки",
        options: forecast.licenseRegions.titles,
        predicateFactory: (selected) => (well: Well) => selected.includes(well.licenseRegion?.title ?? ""),
      },
      {
        title: "Назначение скважины",
        options: ["Добывающая", "Нагнетательная", "Нет истории работы", "Прочего назначения"],
        predicateFactory: (selected) => (well: Well) => {
          const status = forecast.production.wellStatus(well.id);
          if (selected.includes("Добывающая")) {
            if (status?.isMining) {
              return true;
            }
          }
          if (selected.includes("Нагнетательная")) {
            if (status?.isInjecting) {
              return true;
            }
          }
          if (selected.includes("Нет истории работы")) {
            if (status === undefined) {
              return true;
            }
          }
          if (selected.includes("Прочего назначения")) {
            return true;
          }
          return false;
        },
      },
    ],
    [forecast]
  );
};

const InjectionPredictionInputParams = observer(() => {
  const forecast = useForecast()!;
  const treeFilters = useTreeFilters();
  const tree = useMemo(() => new TreeRoot("Все", forecast.wells.allWells, TREE_NESTING_FIELDS, treeFilters), [forecast, treeFilters]);

  const columns = useColumns();
  const store = useMemo(() => new InputParams(forecast, tree), [forecast, tree]);
  const model = useMemo(
    () =>
      new TableModel(
        columns,
        store,
        {
          onRow: ({ value, indexPath }) => ({
            className: classNames(
              {
                [cn.tableRowPrimary]: indexPath.length === 1,
                [cn.invalidRow]: value?.isIntersected,
              },
              value?.periodStatus && (value.periodStatus === "prod" ? cn.prodRow : cn.injRow)
            ),
          }),
        },
        {
          headerHeight: 39,
          rowHeight: 33,
          borderColor: "#f0f0f0",
        }
      ),
    [columns, store]
  );

  const periodModal = usePeriodModal(store.disabledDate, store.producingObjectIds);
  const onPeriodModal = useCallback(() => {
    return periodModal(null).then(({ well, producingObject, range }) => {
      store.addPeriod(well, producingObject, ...range);
    });
  }, [periodModal, store]);

  return (
    <>
      <PageFrameTitlePortal
        model={store}
        onSave={store.save}
        submitCustomTooltip={{ onNotValid: "Данные содержат пересекающиеся периоды. Сохранение невозможно" }}
      >
        <ToggleIntersectedButton hasIntersected={!store.isValid} onlyIntersected={() => store.onlyIntersected()} reset={() => store.resetFilters()} />
        <Button icon={<PlusOutlined />} onClick={onPeriodModal}>
          Добавить период
        </Button>
        <ToolbarButton
          tooltip={{
            title: !store.isValid ? "Данные содержат пересекающиеся периоды. Запуск расчета невозможен" : undefined,
          }}
          type="primary"
          disabled={!store.isValid || forecast.compensationCoefficients.isLoading}
          loading={forecast.injectionResults.isCalculating}
          onClick={() => store.saveCompensation().then(() => forecast.injectionResults.calculate())}
        >
          Запустить расчет
        </ToolbarButton>
      </PageFrameTitlePortal>
      <TreeContextProvider value={tree}>
        <div className={cn.root}>
          <div className={cn.leftPane}>
            <Tree className={cn.tree} title="Скважины" preloadHooks={TREE_PRELOAD_HOOKS} />
          </div>
          <TableContextProvider value={model}>
            <Widget headerClassName={cn.tableHeader} className={cn.table} />
          </TableContextProvider>
        </div>
      </TreeContextProvider>
    </>
  );
});

export { InjectionPredictionInputParams };
