import { Dayjs } from "dayjs";
import { ObjectToCamel, ObjectToSnake, objectToSnake } from "ts-case-convert/lib/caseConvert";

import { global } from "models/global";
import { reqCamel } from "utils/request";

import { reqLogged } from "../logger";

import { LinkMineNode, NodeType, PipeBoundaryCondition, Segment, Station } from "./types";

type SimulationInput = {
  density_oil?: number;
  density_water?: number;
  density_gas?: number;
  mu_water?: number;

  pvdg?: Array<[number, number, number]>;
  pvto?: Array<[number, number, number, number]>;

  nodes: NodeType[];
  segments: Segment[];

  links_mine_node: Array<
    LinkMineNode & {
      boundary_condition: {
        oil_rate: number;
        water_rate: number;
      };
      regime: "inj" | "prod";
    }
  >;

  stations: Array<Station>;

  drain_sources: PipeBoundaryCondition[];
};

type Mine = LinkMineNode & {
  fluidRateT: number;
  oilRateT: number;
  waterRateT: number;
  injRateT: number;
  mineTitle: string;
};

type SegmentType = Segment & { velocity: number; fluidRateM3: number; pressureDelta: number; pressureGradient: number };

type PipeSystemSimulationOutput = {
  nodes: Array<NodeType & { pressure: number }>;
  segments: SegmentType[];
};

type SimulationOutput = {
  prod: PipeSystemSimulationOutput | null;
  inj: PipeSystemSimulationOutput | null;
  mines: Mine[];
};

type SimulationInputRaw = ObjectToCamel<SimulationInput>;

async function calculateInfrastructure(simulationInput: SimulationInputRaw): Promise<SimulationOutput> {
  return reqLogged.post<SimulationOutput>("calculation/infrastructure", objectToSnake(simulationInput));
}

async function calculateMultipleInfrastructures(
  simulationInputs: ({ date: Dayjs } & SimulationInputRaw)[]
): Promise<({ date: Dayjs; drainSources: PipeBoundaryCondition[] } & SimulationOutput)[]> {
  const path = "calculation/infrastructure";

  const promises = simulationInputs.map(async (item) => {
    const response = await reqCamel.post<SimulationOutput>(path, objectToSnake(item));
    return { ...response, date: item.date, drainSources: item.drainSources };
  });
  const responses = await Promise.all(promises);
  global.logger.addNote(`post:${path}`);
  return responses;
}

type ResPipeCost<T> = { res: Array<{ constrCost: number; costs: number[]; reconstCost: number; years: number[] } & T> };

type ResponsePipeCost = {
  mines: Omit<ResPipeCost<Mine>, "reconstCost">;
  segments: ResPipeCost<Segment>;
  stations: ResPipeCost<Station>;
};

async function calculatePipeCost(
  simulationInput: { scenarioId: number } & SimulationInputRaw
): Promise<ResponsePipeCost> {
  return reqCamel.post<ObjectToSnake<ResponsePipeCost>>("pipe/cost", objectToSnake(simulationInput));
}

export type { Mine, ResponsePipeCost, SegmentType, SimulationInputRaw as SimulationInput, SimulationOutput };
export { calculateInfrastructure, calculateMultipleInfrastructures, calculatePipeCost };
