import { FC, useEffect, useState } from "react";
import { Checkbox, Form, Input, Select } from "antd";
import { useForm } from "antd/es/form/Form";
import dayjs from "dayjs";
import { PageFrameTitlePortal } from "routing/pageFrame/pageFrameTitlePortal";

import { Card } from "elements/card/card";
import { DebugZone } from "elements/debugZone/debugZone";
import { Format } from "elements/format/format";
import { TagViewer } from "elements/tagViewer/tagViewer";
import { Project } from "models/project/project";
import { ProjectRaw } from "services/back/project";
import { DateRange } from "utils/dateRange";
import { Range } from "utils/range";

import dp from "./dataProject.module.less";

function compareObjects<T extends object>(current: T, original: T) {
  const changedFields = {} as T;
  if (Object.keys(current).length > 0) {
    for (const key in current) {
      if (current[key] !== original[key]) {
        changedFields[key] = current[key];
      }
    }
  }
  return changedFields;
}

const preferenceOptions = ["Льгот нет", "Льготная", "0%", "10%"].map((title) => ({ value: title, label: title }));

const lossesTransferOptions = [
  { value: true, label: "С переносом убытков" },
  { value: false, label: "Без переноса убытков" },
];

const condensateOptions = [
  { label: "В смеси", value: 1 },
  { label: "Раздельно", value: 2 },
];

const RangeFormat: FC<{ children: Range | DateRange }> = ({ children }) => {
  if (children instanceof Range) {
    return (
      <>
        [<Format unit="int">{children.from}</Format> - <Format unit="int">{children.to}</Format>)
      </>
    );
  }
  return (
    <>
      [<Format dateFormat="monthYear">{children.from}</Format> - <Format dateFormat="monthYear">{children.to}</Format>]
    </>
  );
};

type FormValues = {
  title: string;
  fieldTitle: string;
  earlyestPlanYear: number;
  preferences?: ProjectRaw["preferences"];
  isMajorProject: boolean;
  isInfrastructure: boolean;
  isLossTransfer?: boolean;
};

const MainProjectParams: FC<{ project: Project }> = ({ project }) => {
  const [finishData, setFinishData] = useState<FormValues | {}>({});
  const [isLoading, setIsLoading] = useState(false);
  const [form] = useForm();

  useEffect(() => {
    const initialValues = project
      ? {
          title: project.title,
          fieldTitle: project.fieldTitle,
          preferences: project.preferences,
          oilWays: project.oilWays,
          condensate: 1,
          isAssetAlgoNdd: project.isAssetAlgoNdd,
          isLossTransfer: project.isLossTransfer,
          isMajorProject: project.isMajorProject,
          isInfrastructure: project.isInfrastructure,
        }
      : undefined;
    form.setFieldsValue(initialValues);
  }, [form, project]);

  const matchValues = () => {
    if (project) {
      const result = compareObjects(form.getFieldsValue(), project);
      setFinishData(result);
    }
  };

  const handleFinish = () => {
    setIsLoading(true);
    const req = structuredClone(finishData);
    if ("condensate" in req) {
      delete req.condensate;
    }
    project.applyFromFormData(req).then(() => {
      setFinishData({});
      setIsLoading(false);
    });
  };

  return (
    <Card bodyClassName={dp.detailCardParent}>
      <Form form={form} className={dp.form} layout="vertical" onFinish={handleFinish} onValuesChange={matchValues}>
        <PageFrameTitlePortal submitting={isLoading} onSave={form.submit} model={{ isUpdated: !!Object.keys(finishData).length }} />
        <div>
          <Form.Item name="title" label="Название">
            <Input />
          </Form.Item>
          <TagViewer className={dp.ways} title="Месторождение">
            {["Месторождение имени Некрасова"]}
          </TagViewer>
          <DebugZone>Опорные временные характеристики</DebugZone>
          <ul>
            <li>
              Год начала инвестиций в проект <Format unit="int">{project.startYear}</Format>{" "}
              <DebugZone>invest_start_year просто задан на беке для всех проектов единый на все проекты. Задаётся с точностью до года</DebugZone>
            </li>
            <li>
              Дата оценки <Format dateFormat="monthYear">{project.actualStateDate}</Format>{" "}
              <DebugZone>actual_state_date задаётся на фронте с точностью до месяца на весь проект но реально хранится с точностью до дня</DebugZone>
            </li>
            <li>
              Момент создания проекта <Format dateFormat="accurate">{dayjs(project.createdAt)}</Format>{" "}
              <DebugZone>created_at фиксируется в момент открытия модалки создания проекта, не используется для расчетов</DebugZone>
            </li>
            <li>
              Дата окончания планирования <Format unit="int">{project.lastForecastYear}</Format>{" "}
              <DebugZone>
                {" "}
                - property.stop вводится в момент создания проекта для всех сценариев. Служит как отсечка окончания любой планируемой в рамках проекта
                деятельности
              </DebugZone>
            </li>
            <li>
              Год перехода на НДД <Format unit="int">{project.licenseRegions.nddRegion?.ndd!.transitionYear ?? null}</Format>{" "}
              <DebugZone>
                - год перехода на НДД первого из участков, переходящих на НДД (просто не доделана логика, берётся первый попавшийся. Надеемся, что
                единственный)
              </DebugZone>
            </li>
          </ul>
          <DebugZone>
            Хранимые но не используемые на фронте временные характеристики
            <ul>
              <li>property.invest_year - никак не используется на фронте, на беке всегда равен invest_start_year</li>
              <li>property.start - тоже никак не используется на фронте, на беке всегда равен invest_start_year</li>
              <li>
                property.plan_year - при создании проекта сохраняется как actual_state_date.year + 1, при запуске расчета не берётся с бека а
                вычисляется на фронте
              </li>
              <li>scenarios[index].plan_end_year - Возникает на бекенде, всегда равен 2054, на фронтенде не используется</li>
            </ul>
          </DebugZone>
          <div>
            <DebugZone>Производные временные характеристики проекта</DebugZone>
            <ul>
              <li>
                Год ожидания <Format unit="int">{project.fact.lastDescribedYear}</Format>{" "}
                <DebugZone>- год, с которого начинается описание прогноза</DebugZone>
              </li>
              <li>
                Первый прогнозный год <Format unit="int">{project.fact.lastDescribedYear + 1}</Format>{" "}
                <DebugZone>
                  - год, следующий за годом ожидания. Понятийно присутствует в экономическом расчете но по факту в интерфейсе пока не проявился
                </DebugZone>
              </li>
              <li>
                Диапазон задания фактических параметров <RangeFormat>{project.fact.factRange}</RangeFormat>{" "}
                <DebugZone>- [invest_start_year, Год ожидания)</DebugZone>
              </li>
              <li>
                Диапазон задания параметров прогноза <RangeFormat>{project.fact.forecastRange}</RangeFormat>{" "}
                <DebugZone>
                  - [Год ожидания, property.stop + 1), ранее использовался plan_end_year но его использование полностью убрано на фронтенде
                </DebugZone>
              </li>
              <li>
                Общий диапазон параметров <RangeFormat>{project.fact.wholeRange}</RangeFormat>{" "}
                <DebugZone>- [invest_start_year, property.stop + 1), просто join двух реньжей</DebugZone>
              </li>
              <DebugZone tag="li">
                Год спрогнозированного окончания добычи <Format>{null}</Format> - вычисленный на основе РКП и технологического прогноза год окончания
                добычи, зависит от прогноза, алгоритмика не зафиналена, просто зафиксировано наличие понятия
              </DebugZone>
              <li>
                Дата начала технологического прогноза <RangeFormat>{project.fact.forecastDateRange}</RangeFormat>
                <DebugZone>- [дата оценки + 1 месяц, 31 декабря года окончания планирования]</DebugZone>
              </li>
            </ul>
          </div>
          <div className={dp.gridFields}>
            <Form.Item name="preferences" label="Льготы по ЭП">
              <Select options={preferenceOptions} />
            </Form.Item>
            <Form.Item name="isLossTransfer" label="Расчет налога на прибыль">
              <Select options={lossesTransferOptions} />
            </Form.Item>
            <Form.Item name="condensate" label="Реализация конденсата">
              <Select value={1} disabled options={condensateOptions} />
            </Form.Item>
          </div>
          <div className={dp.projectCheckbox}>
            <Form.Item name="isMajorProject" valuePropName="checked">
              <Checkbox>Крупный/приоритетный проект</Checkbox>
            </Form.Item>
            <Form.Item name="isInfrastructure" valuePropName="checked">
              <Checkbox>Инфраструктурный проект</Checkbox>
            </Form.Item>
            <Form.Item name="isAssetAlgoNdd" valuePropName="checked">
              <Checkbox>Расчет НДД по алгоритму актива</Checkbox>
            </Form.Item>
          </div>
          <div className={dp.waysBlock}>
            <div className={dp.waysTitle}>Направления</div>
            <TagViewer className={dp.ways} title="Нефть">
              {["Западная Сибирь (малосернистая нефть)", "Западная Сибирь"]}
            </TagViewer>
            <TagViewer className={dp.ways} title="Нефтяной газ">
              {["Западная Сибирь"]}
            </TagViewer>
          </div>
        </div>
      </Form>
    </Card>
  );
};

export { MainProjectParams };
