import { ChildrenStoreArray, ColumnRaw, TableModel, TableNode } from "@okopok/components/Table";
import { Popconfirm } from "antd";
import dayjs from "dayjs";
import { computed, makeObservable, observable, runInAction } from "mobx";

import { DeleteButton } from "elements/deleteButton/deleteButton";
import { Format } from "elements/format/format";
import { Loader } from "elements/loader";
import { global } from "models/global";
import { Project } from "models/project/project";
import { Projects } from "models/project/projects";
import { TreeRoot } from "models/tree/tree";

import { UserCard } from "./userCard";

import cn from "./projectsTable.module.less";

type FieldInfo = { id: number; title: string; ngdo: string; tpp: string };

type DRow = {
  id: number;
  title: string;
  createdAt: string | null;
  scenarioNumber: number;
  firstForecastYear: string;
  createdBy: number | null;
  removable?: boolean;
  wellsBaseNumber: number | null;
  delete: () => void;
};

class ProjectsStore extends TableNode<DRow, ProjectNode> {
  constructor(projects: Project[], userId: number, deleteProject: (id: number) => void) {
    super();

    runInAction(() => {
      this.childrenStore = new ChildrenStoreArray(
        this,
        projects.map((project) => new ProjectNode(this, project, userId, deleteProject))
      );
    });
  }
}

class ProjectNode extends TableNode<DRow> {
  public asDRow(): DRow {
    return {
      id: this.project.id,
      title: this.project.title,
      createdAt: this.project.createdAt,
      scenarioNumber: this.project.scenarios.length,
      firstForecastYear: this.project.actualStateDate.locale("ru").format("MMMM YYYY"),
      createdBy: this.project.createdBy,
      wellsBaseNumber: this.project.wellsBaseNumber,
      removable: this.project.createdBy === this.userId,
      delete: () => this.deleteProject(this.project.id),
    };
  }
  constructor(parentNode: ProjectsStore, public readonly project: Project, private userId: number, private deleteProject: (id: number) => void) {
    super(parentNode);
    runInAction(() => (this.childrenStore = null));
  }
}

const NESTING_FIELDS = [
  {
    key: "parent1",
    title: "НГДО",
    getter: ({ ngdo }: FieldInfo) => ngdo,
  },
  {
    key: "parent2",
    title: "ТПП",
    getter: ({ tpp }: FieldInfo) => tpp,
  },
];

const columns = [
  {
    key: "index",
    title: "No.пп",
    width: { min: 55, max: 70, competitiveness: 1 },
    isSticky: true,
    render: (_, { absoluteIndex }) => absoluteIndex,
  },
  {
    key: "title",
    title: "Название проекта",
    width: { min: 350, max: 600, competitiveness: 1 },
    render: (value) => value && <Format>{value}</Format>,
  },
  {
    key: "createdAt",
    title: "Создан",
    width: { min: 200, max: 440, competitiveness: 1 },
    render: (value) => value && <Format>{dayjs(value)}</Format>,
  },
  {
    key: "createdBy",
    title: "Создатель проекта",
    width: { min: 255, max: 400, competitiveness: 1 },
    render: (value) => <UserCard id={value} />,
  },
  {
    key: "scenarioNumber",
    title: "Сценариев",
    width: { min: 100, max: 100, competitiveness: 1 },
    render: (value) => <Format>{value}</Format>,
  },
  {
    key: "wellsBaseNumber",
    title: "Скважин БФ",
    width: { min: 110, max: 110, competitiveness: 1 },
    render: (value) => <Format>{value}</Format>,
  },
  {
    key: "lastUpdate",
    title: "Последнее изменение",
    width: { min: 200, max: 440, competitiveness: 1 },
    render: (_, tableItem) => {
      const lastNode = global.logger.lastNodeByProject(tableItem.value?.id ?? 0);
      if (lastNode) {
        return <Format dateFormat="accurate">{dayjs(lastNode.date)}</Format>;
      } else if (lastNode === null) {
        return <Format>{null}</Format>;
      }
      return <Loader />;
    },
  },
  {
    key: "firstForecastYear",
    title: "Дата оценки",
    width: { min: 215, max: 440, competitiveness: 1 },
    render: (value) => value,
  },
  {
    title: "",
    key: "sticky",
    width: 55,
    render: (_, { value }) =>
      value?.removable ? (
        <Popconfirm title="Удалить проект?" cancelText="Отмена" okText="Удалить" onConfirm={() => value.delete()}>
          <DeleteButton />
        </Popconfirm>
      ) : null,
    onCell: () => ({ className: cn["delete-cell"] }),
  },
] as ColumnRaw<DRow>[];

class Fields {
  public readonly tree: TreeRoot<FieldInfo>;
  public onNavigateProject?: (id: number) => void;

  private currentProjectIndex: number = 0;
  currentFieldId: number = 34;

  constructor(fieldsList: FieldInfo[], public projects: Projects, private userId: number) {
    this.tree = new TreeRoot(null, fieldsList, NESTING_FIELDS, [], false);
    this.tree.selectedLevels = [0, 1];

    makeObservable<Fields, "currentProjectIndex">(this, {
      projects: observable,
      currentProjectIndex: observable,
      currentFieldId: observable,
      currentProject: computed,
      tableModel: computed,
    });
  }

  get currentProject(): Project {
    return this.currentProjects[this.currentIndex];
  }

  get currentProjects(): Project[] {
    return Array.from(this.projects.values!).filter((project) => {
      return project.fields.items?.find((item) => item.id === this.currentFieldId);
    });
  }

  get tableModel(): TableModel<DRow> | null {
    if (this.currentProjects.length > 0) {
      const store = new ProjectsStore(this.currentProjects, this.userId, this.projects.deleteProject);
      return new TableModel(columns, store, {
        onRow: (v, index) => ({
          className: index === this.currentIndex ? cn["selected-row"] : cn["row"],
          onClick: () => runInAction(() => (this.currentProjectIndex = index)),
          onDoubleClick: () => (this.onNavigateProject ? this.onNavigateProject(this.currentProject.id) : null),
        }),
      });
    } else {
      return null;
    }
  }

  get currentIndex(): number {
    return this.currentProjectIndex;
  }
}

export { Fields };
export type { FieldInfo };
