import { type FC } from "react";
import { Outlet } from "react-router-dom";
import { ExportOutlined } from "@ant-design/icons";
import { Button } from "antd";

import { Logo } from "elements/logo/logo";
import { ModalContextProvider } from "elements/modal/modal";
import { ProjectContextProvider } from "models/project/context/projectContext";

import { FactYearsToggler } from "./factYearsToggler/factYearsToggler";
import { ModesMenu } from "./modesMenu/modeMenu";
import { SecondaryMenu } from "./secondaryMenu/secondaryMenu";

import cn from "./authorizedOutline.module.less";

const AuthorizedOutline: FC = () => (
  <>
    <header>
      <Logo className={cn.logo} />
      <div className={cn.buttons}>
        <FactYearsToggler />
        <Button type="primary" ghost onClick={() => (window.location.href = "/mok")} icon={<ExportOutlined />}>
          Макет приложения
        </Button>
      </div>
    </header>
    <div className={cn.content}>
      <ModesMenu />
      <div className={cn.layout}>
        <SecondaryMenu />
        <div className={cn.main}>
          <Outlet />
        </div>
      </div>
    </div>
  </>
);

const AuthorizedPageWithModalContext = () => (
  <ModalContextProvider>
    <ProjectContextProvider>
      <AuthorizedOutline />
    </ProjectContextProvider>
  </ModalContextProvider>
);

export { AuthorizedPageWithModalContext as AuthorizedOutline };
