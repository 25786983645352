import { reqCamel } from "utils/request";

import { LinkMineNode, Segment, Station } from "./types";

const formatResult = <T extends { title: string }>(data: T[]): (T & { id: number; title: string })[] =>
  data.map((item, index) => ({
    ...item,
    id: index + 1,
  }));

type MineBack = { numberOfWells: number; title: string } & Omit<LinkMineNode, "nodeUuid" | "mine_id">;
type StationBack = { title: string; power: number } & Omit<Station, "nodeUuid" | "startedAt" | "finishedAt">;

type CatalogBack = {
  mines: MineBack[];
  segments: ({ title: string } & Segment)[];
  stations: StationBack[];
};

type FormatedCatalogType = { id: number; title: string };

type StationType = FormatedCatalogType & StationBack;
type MineType = FormatedCatalogType & Omit<MineBack, "mineId">;
type SegmentType = FormatedCatalogType & Omit<Segment, "startedAt" | "finishedAt" | "firstNodeUuid" | "secondNodeUuid">;

type CatalogType = {
  mines: MineType[];
  segments: SegmentType[];
  stations: StationType[];
};

async function getInfrastructureCatalog(catalogId?: number): Promise<CatalogType> {
  const path = catalogId ? `catalog_pipe/?catalog=${catalogId}` : "catalog_pipe/";
  const { mines, segments, stations } = await reqCamel.get<CatalogBack>(path);
  return {
    mines: formatResult(mines),
    segments: formatResult(segments),
    stations: formatResult(stations),
  };
}

async function saveCatalog(catalog: CatalogType) {
  return await reqCamel.post<CatalogType>("catalog_pipe/", catalog);
}

export { getInfrastructureCatalog, saveCatalog };
export type { CatalogType, MineType, SegmentType, StationType };
