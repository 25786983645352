import type { FC } from "react";
import { Link, useLocation } from "react-router-dom";
import { Breadcrumb } from "antd";
import { observer } from "mobx-react";

import { Format } from "elements/format/format";
import { useForecast } from "models/project/fact/forecast/forecast";
import { useForecasts } from "models/project/fact/forecast/forecasts";
import { useProject } from "models/project/project";
import { useProjects } from "models/project/projects";
import { conditionally, conditionallyArr } from "utils/conditionally";

import cn from "./pageFrame.module.less";

const useBreadcrumbItems = (title: React.ReactNode) => {
  const project = useProject();
  const projects = useProjects();
  const forecast = useForecast();
  const forecasts = useForecasts();
  const { pathname } = useLocation();
  let currentKey = pathname.split("/").pop();

  if (project === null) {
    return null;
  }

  let items = [
    {
      title: (
        <Link to="/">
          <Format>{project?.title}</Format>
        </Link>
      ),
      ...conditionally((projects?.length ?? 0) > 1, {
        menu: {
          items: projects.selector?.map(({ value, label }) => ({ key: value, label: <Link to={`/${value}/${currentKey}`}>{label}</Link> })),
        },
      }),
    },
    ...conditionallyArr(forecast !== null, () => ({
      title: (
        <Link to={`/${project?.id}/${forecast?.id}`}>
          <Format>{forecast?.title}</Format>
        </Link>
      ),
      ...conditionally((forecasts?.length ?? 0) > 1, {
        menu: {
          items: forecasts?.selector?.map(({ value, label }) => ({
            key: value,
            label: <Link to={`/${project?.id}/${value}/settings`}>{label}</Link>,
          })),
        },
      }),
    })),
    {
      title,
    },
  ];

  return items;
};

const BreadcrumbComponents: FC<{ title: React.ReactNode }> = observer(({ title }) => {
  const items = useBreadcrumbItems(title);

  // единственная страница без хлебной крошки - страница выбора проекта
  if (items === null) {
    return null;
  }

  return <Breadcrumb className={cn.breadcrumb} items={items} />;
});

export { BreadcrumbComponents as Breadcrumb };
