import { FC } from "react";
import { observer } from "mobx-react";
import { PageFrame, TabItem } from "routing/pageFrame/pageFrame";

import { Preloader } from "features/preloader/preloader";
import { useECYStore } from "features/useMetrics/useECYStore";
import type { State } from "models/project/fact/fact";
import { useForecasts } from "models/project/fact/forecast/forecasts";

import { CompareScenario } from "./compareScenario/compareScenario";
import { FactorAnalysis } from "./factorAnalysis/factorAnalysis";

// const RENDER = (v: { val: number; diff: number } | null | undefined) => {
//   if (v === null || v === undefined) {
//     return <Format>{v}</Format>;
//   }
//   if (v.diff === 0) {
//     return <Format>{v.val}</Format>;
//   }
//   return (
//     <div className={cn.value}>
//       <div>
//         <Format>{v.val}</Format>
//       </div>
//       <div>
//         <span className={classNames({ [cn.grow]: v.diff > 0, [cn.low]: v.diff < 0 })}>
//           {v.diff > 0 ? "+" : "-"}&nbsp;<Format>{Math.abs(v.diff)}</Format>
//         </span>
//       </div>
//     </div>
//   );
// };

// const RecalculateButton: FC<{ fact: State }> = observer(({ fact }) => {
//   const isAnyComputing = fact.forecasts.find(({ result }) => result === undefined) !== null;

//   const onRecalculate = useCallback(() => {
//     for (const { calculate } of fact.forecasts.values ?? []) {
//       calculate();
//     }
//   }, [fact.forecasts.values]);

//   if (isAnyComputing) {
//     return (
//       <Loader>
//         Идёт расчет ({[...(fact.forecasts.values ?? [])].reduce((counter, { result }) => counter + (result !== undefined ? 1 : 0), 0)} /{" "}
//         {fact.forecasts.length})
//       </Loader>
//     );
//   }
//   return <Button onClick={onRecalculate}>Пересчитать всё</Button>;
// });

// const useColumns = (forecasts: Forecasts) =>
//   useMemo(
//     () => [
//       ...SHARED_COLUMNS,
//       ...forecasts.map(({ title, id }) => ({
//         title: (
//           <Button
//             type="text"
//             className={classNames(cn.title, { [cn.favorite]: forecasts.comparison.favorite === id })}
//             icon={<Icon className={cn.icon} viewBox="0 0 13 12" content={<StarIcon />} />}
//             onClick={() => (forecasts.comparison.favorite = id)}
//           >
//             {title}
//           </Button>
//         ),
//         id,
//         dataIndex: id,
//         render: RENDER,
//       }))!,
//     ],
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//     [forecasts, forecasts.comparison.favorite]
//   );

const TABS: TabItem[] = [
  {
    key: "compareScenario",
    label: "Сравнение сценариев",
    children: <CompareScenario />,
  },
  {
    key: "factorAnalysis",
    label: "Факторный анализ",
    default: true,
    children: <FactorAnalysis />,
  },
];

const Comparison: FC<{ fact: State }> = observer(() => {
  return (
    <Preloader hooks={[useForecasts, useECYStore]}>
      <PageFrame title="Сравнение сценариев" tabs={TABS} tabsWidthFix={464} />
    </Preloader>
  );
});

export { Comparison };
