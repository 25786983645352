import { action, computed, makeAutoObservable, makeObservable } from "mobx";

import type { ParamsRow } from "features/useMetrics/paramsRow";
import { LoadableStore } from "models/loadableStore/loadableStore";
import { ParamsNode } from "models/params/paramsNode";
import { ecySource } from "services/finance/ecy";
import { checkSystemECY, ECY_BASE_ID } from "services/finance/ecyPatcher";
import { MetricTree, riseMetrics } from "services/finance/utils";
import { Range } from "utils/range";
import { numerateTree } from "utils/tree";

import { Fact } from "./fact";

class ECY {
  public metrics?: ParamsNode[];

  constructor(
    public id: number,
    rawData: MetricTree[],
    public title: string,
    public discount: number,
    public readonly years: Range,
    private state: Fact
  ) {
    this.fromRaw(rawData);
    makeAutoObservable(this);
  }

  public fromRaw(raw: MetricTree[]): void {
    this.metrics = raw.map(ParamsNode.fromRaw);
    this.metrics.forEach((v) => v.propagateParent());
  }

  public get asRaw(): MetricTree[] {
    const data = this.metrics!.flatMap((node) => node.flatten());
    return riseMetrics(data);
  }

  public setDiscount(value: number) {
    this.discount = value;
  }

  public get isLoading() {
    return this.metrics === undefined;
  }

  static modifyWays(data: ParamsRow[]) {
    const ways = ["Западная Сибирь (малосернистая нефть)", "Западная Сибирь"];

    const rowWays = data.pop();
    if (!rowWays || !rowWays.children) return;

    rowWays.children = rowWays.children.filter((el) => !el.title.includes("направление 3"));

    rowWays.children = rowWays.children.map((el, i) => ({
      ...el,
      title: `${ways[i]} (направление ${i + 1})`,
    }));

    const oilGas = rowWays.children.pop();
    if (oilGas) {
      oilGas.title = `${ways[1]} (нефтяной газ)`;
      rowWays.children.push(oilGas);
    }
    data.push(rowWays);
  }

  public get tableItems(): ParamsRow[] {
    console.assert(this.metrics !== undefined, "Попытка отобразить таблицу до завершения её загрузки");
    const result = this.metrics!.map((v) => v.tableItems);
    ECY.modifyWays(result);
    numerateTree(result);
    return result;
  }

  public clone() {
    const getRandomId = () => {
      const min = -10000000;
      const max = -10;
      return Math.floor(Math.random() * (max - min + 1) + min);
    };

    const newId = getRandomId();
    const newEcy = new ECY(newId, structuredClone(this.asRaw), this.title, this.discount, this.years, this.state);

    return newEcy;
  }
}

class ECYStore extends LoadableStore<ECY> {
  constructor(private years: Range, private state: Fact, private origin?: ECYStore) {
    const [getData, setData] = ecySource({ fact: state.projectId });

    super(
      async () => {
        const data = await getData(years);
        return data.map((raw) => new ECY(raw.id, raw.usc, raw.title, raw.discount, years, state));
      },
      async (data: ECY[]) => {
        setData(
          data.map((ecy) => ({
            id: ecy.id,
            title: ecy.title,
            usc: ecy.asRaw,
            discount: ecy.discount,
          }))
        );
        return data;
      }
    );

    makeObservable(this, {
      createNewECY: action,
      deleteEcy: action,
      update: action,
      isUpdated: computed,
      isCompleated: computed,
      isValid: computed,
      clone: computed,
      listSystemECY: computed,
      listECY: computed,
    });
  }

  get listSystemECY() {
    return Array.from(this.values ?? []).filter((ecy) => checkSystemECY(ecy));
  }

  get listECY() {
    return Array.from(this.values ?? []).filter((ecy) => !checkSystemECY(ecy));
  }

  public isSystemECY(ecy: ECY): boolean {
    return checkSystemECY(ecy)!; // shouldnt return undefined when ecy is not undefined;
  }

  // check if not submited ecy models are presented
  public get isUpdated() {
    if (!this.origin) {
      return false;
    }
    if (this.isLoading || this.origin.isLoading) {
      return false;
    }
    if (this.length !== this.origin.length) {
      return true;
    }
    const discounts = new Map(Array.from(this.values!, (o) => [o.id, o.discount]));
    const discountsOrigin = new Map(Array.from(this.origin.values!, (o) => [o.id, o.discount]));

    if (![...discounts.entries()].every(([id, disc]) => discountsOrigin.get(id) === disc)) {
      return true;
    }
    return false;
  }

  public get isCompleated() {
    return true;
  }

  public get isValid() {
    return true;
  }

  public async update(obj: ECYStore) {
    if (this.isLoading || obj.isLoading) {
      console.warn("Попытка отправить изменения ЕСУ до загрузки");
      return undefined;
    }
    return this.reset(Array.from(obj.values!));
  }

  public createNewECY(options: { title?: string | undefined | null; baseId?: number | undefined | null }) {
    const { title = null } = options ? options : {};

    const baseId = ECY_BASE_ID;

    const baseECY = this.at(baseId);
    if (!baseECY) {
      console.error("Не возможно создать новый ЕСУ, по какойто причине базовый ЕСУ отсутсвует");
      return null;
    }
    const newEcy = baseECY.clone();
    if (title != null) {
      newEcy.title = title;
    }

    this.add(newEcy);
    // this.sendChanges();
    return newEcy.id;
  }

  public deleteEcy(ecyId: number) {
    if (checkSystemECY(ecyId)) {
      console.error("Попытка удалить системные ЕСУ");
      return false;
    }
    const result = this.delete(ecyId);
    // this.sendChanges();
    return result;
  }

  public get clone(): ECYStore {
    if (this.isLoading) {
      console.error("Попытка клонировать изменения ЕСУ до загрузки");
      // return undefined;
    }

    const instance = new ECYStore(this.years, this.state, this);

    // Тут вообще интересная чтука получается при клонированииии LoadableStore, получаетсся так что идет запрос еще один на бекенд, и это скорее не копия текущего, а "более актуальная копия"
    // instance.reset(Array.from(this.values!));
    return instance;
  }
}

export { ECY, ECYStore };
