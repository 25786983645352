import { reqCamel } from "utils/request";

type Role = {
  title: string;
  id: number;
  userIds: number[];
  projectIds: number[];
};

type ProjectRole = Omit<Role, "projectIds">;

type AssignRole = {
  roleId: number;
  projectId: number;
  userId: number;
};

const getRoles = async (): Promise<Role[]> => reqCamel.get<Role[]>("roles");

const getProjectRoles = async (projectId: number): Promise<ProjectRole[]> =>
  reqCamel.get<ProjectRole[]>(`roles/projects/${projectId}`);

const assignRole = (roleId: number, projectId: number, userId: number) =>
  reqCamel.post<AssignRole>("roles/assign_role", { roleId, projectId, userId });

const deleteRole = (roleId: number, projectId: number, userId: number) =>
  reqCamel.delete<AssignRole>("roles/users", { roleId, projectId, userId });

const deleteUser = (roleIds: number[], projectId: number, userId: number) =>
  Promise.all(roleIds.map((roleId) => deleteRole(roleId, projectId, userId)));

export { assignRole, deleteRole, deleteUser, getProjectRoles, getRoles };
export type { ProjectRole };
