import { useMemo } from "react";
import { ColumnRaw, TableModel } from "@okopok/components/Table";
import { ExpandButton } from "@okopok/components/Table/widgets/ExpandButton/ExpandButton";
import { Tooltip } from "antd";
import classNames from "classnames";
import dayjs from "dayjs";

import { Format } from "elements/format/format";
import { Icon } from "elements/icon/icon";
import { ProblemIndicator } from "features/infrastructure/problemIndicator/problemIndicator";
import { useInfrastructure } from "features/infrastructure/useInfrastructure";

import { DRowPipes, PipesModel } from "./tableManager/pipes";
import { ReactComponent as WarningIcon } from "./warning.svg";

import cn from "../infrastructureTable.module.less";

const useColumns = (mode: "prod" | "inj"): ColumnRaw<DRowPipes>[] => {
  return useMemo(
    (): ColumnRaw<DRowPipes>[] => [
      {
        title: "№",
        width: { min: 40, max: 80, competitiveness: 1 },
        key: "index",
        isSticky: true,
        onCell: () => ({ className: cn.tableCell }),
        render: (_, pipe) => pipe.absoluteIndex,
      },
      {
        key: "expand",
        title: null,
        isSticky: true,
        width: { min: 30, max: 30, competitiveness: 1 },
        render: (_, { expand, value }) => {
          if (expand?.status !== undefined) {
            return <ExpandButton expand={expand} />;
          }
          return (
            value?.isProblem && (
              <Tooltip title="Превышены лимиты">
                {" "}
                <Icon width="14px" height="14px" viewBox="0 0 14 14" content={<WarningIcon />} />
              </Tooltip>
            )
          );
        },
        onCell: () => ({ className: cn.tableCell }),
      },
      {
        title: "Название участка",
        width: { min: 200, max: 250, competitiveness: 1 },
        isSticky: true,
        dataKey: "title",
        onCell: () => ({ className: cn.tableCell }),
      },
      {
        title: "Дата",
        width: { min: 100, max: 200, competitiveness: 1 },
        isSticky: true,
        dataKey: "date",
        onCell: () => ({ className: cn.tableCell }),
        render: (value, { expand }) => {
          if (expand?.status !== undefined) {
            return <></>;
          }
          return dayjs(value).format("MM.YYYY");
        },
      },
      {
        title: "Вид трубопровода",
        width: { min: 220, max: 250, competitiveness: 1 },
        dataKey: "type",
      },
      {
        title: "Расход жидкости, м3/сут",
        width: { min: 150, max: 180, competitiveness: 1 },
        dataKey: "fluidRateM3",
        onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
        onCell: () => ({ className: cn.tableCellAlignRight }),
        render: (value, { expand }) => {
          if (expand?.status !== undefined) {
            return <></>;
          }
          return <Format>{value}</Format>;
        },
      },
      {
        title: "Перепад давления, бар",
        width: { min: 130, max: 180, competitiveness: 1 },
        dataKey: "pressureDelta",
        onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
        onCell: () => ({ className: classNames(cn.tableCellAlignRight, cn.noPadding) }),
        render: (value, pipe) => {
          if (pipe.expand?.status !== undefined) {
            return <></>;
          }
          return <ProblemIndicator limit={pipe.value?.limitingPressureGradient ?? 0}>{value}</ProblemIndicator>;
        },
      },
      {
        title: "Давление в начале, бар",
        width: { min: 120, max: 150, competitiveness: 1 },
        dataKey: "firstPressure",
        onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
        onCell: () => ({ className: classNames(cn.tableCellAlignRight, cn.noPadding) }),
        render: (value, pipe) => {
          if (pipe.expand?.status !== undefined) {
            return <></>;
          }
          return <ProblemIndicator limit={pipe.value?.limitingPressureGradient ?? 0}>{value}</ProblemIndicator>;
        },
      },
      {
        title: "Давление в конце, бар",
        width: { min: 120, max: 150, competitiveness: 1 },
        dataKey: "secondPressure",
        onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
        onCell: () => ({ className: classNames(cn.tableCellAlignRight, cn.noPadding) }),
        render: (value, pipe) => {
          if (pipe.expand?.status !== undefined) {
            return <></>;
          }
          return <ProblemIndicator limit={pipe.value?.limitingPressureGradient ?? 0}>{value}</ProblemIndicator>;
        },
      },
      {
        title: "Градиент давления, бар/м ",
        width: { min: 150, max: 180, competitiveness: 1 },
        dataKey: "pressureGradient",
        onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
        onCell: () => ({ className: classNames(cn.tableCellAlignRight, cn.noPadding) }),
        render: (value, pipe) => {
          if (pipe.expand?.status !== undefined) {
            return <></>;
          }
          return <ProblemIndicator limit={pipe.value?.limitingPressureGradient ?? 0}>{value}</ProblemIndicator>;
        },
      },
      {
        title: "Скорость потока жид-ти, м/сек ",
        width: { min: 150, max: 180, competitiveness: 1 },
        dataKey: "velocity",
        onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
        onCell: () => ({ className: classNames(cn.tableCellAlignRight, cn.noPadding) }),
        render: (value, pipe) => {
          if (pipe.expand?.status !== undefined) {
            return <></>;
          }
          return <ProblemIndicator limit={pipe.value?.limitingVelocity ?? 0}>{value}</ProblemIndicator>;
        },
      },
    ],
    []
  );
};

const usePipesTableModel = (mode: "prod" | "inj") => {
  const infrastructure = useInfrastructure()!;
  const { isCalculation } = infrastructure.calculateStore;

  const columns = useColumns(mode); // eslint-disable-next-line react-hooks/exhaustive-deps
  const store = useMemo(() => new PipesModel(infrastructure, mode), [infrastructure, mode, isCalculation]);
  const model = useMemo(
    () =>
      new TableModel(columns, store, {
        onRow: ({ indexPath, expand, value }) => ({
          className: classNames({
            [cn.tableRowPlain]: expand === undefined,
            [cn.tableRowPrimary]: indexPath.length === 1,
            [cn.columnWarning]: value?.isProblem,
          }),
        }),
      }),
    [columns, store]
  );

  return { model, store };
};

export { usePipesTableModel };
