import { type FC, useMemo } from "react";
import { Table } from "@okopok/components/Table/Table";
import { Typography } from "antd";
import { observer } from "mobx-react";
import { useParamsModel } from "routing/outlines/params/paramsPage";
import { PageFrameTitlePortal } from "routing/pageFrame/pageFrameTitlePortal";

import { FullScreenEmpty } from "elements/fullScreen/fullScreen";
import { Column } from "features/tableDebug/simpleTable";
import { SimpleTableContext } from "features/tableDebug/simpleTable";
import { ParamsRow } from "features/useMetrics/paramsRow";

import { OptionedScalar } from "./optionedScalar";
import { Scalar } from "./scalar";
import { GenericTableStore } from "./tableModel";

import cn from "./genericPage.module.less";

const GenericPage: FC<{}> = observer(() => {
  const model = useParamsModel();
  const ScalarInput = model.optionedScalars === null ? Scalar : OptionedScalar;
  const modelTable = model?.table;

  const getTotalRows = (items: ParamsRow[]) => {
    return items.reduce((count: number, item: ParamsRow) => {
      let total = 1;
      if (item.children && item.children.length > 0) {
        total += getTotalRows(item.children);
      }

      return count + total;
    }, 0);
  };
  const lineCount = modelTable?.tableItems ? getTotalRows(modelTable?.tableItems) : 0;

  const columns = useMemo<Column[]>(
    () =>
      modelTable
        ? [
            {
              title: "Параметр",
              dataKey: "param",
              type: "string",
              width: 350,
              showEditRowBtn: true,
            },
            {
              title: "Единица измерения",
              dataKey: "measure",
              type: "string",
              width: 100,
            },
            ...[...modelTable.years].map(
              (year): Column => ({
                title: `${year}`,
                dataKey: `${year}`,
                type: "set",
                width: 140,
                editable: true,
              })
            ),
          ]
        : [],
    [modelTable]
  );

  const store = useMemo(() => (modelTable && new GenericTableStore(modelTable, modelTable.years, modelTable.tableItems)) ?? undefined, [modelTable]);

  if (model.table === null && model.scalar === null && model.optionedScalars === null) {
    return <FullScreenEmpty>В разработке</FullScreenEmpty>;
  }

  if (model.isOnlyTable) {
    return (
      <SimpleTableContext
        exportFileName={modelTable?.title || ""}
        columns={columns}
        data={store}
        tableOptions={{
          onRow: ({ indexPath, expand }) => ({
            className: expand === undefined ? cn.tableRowPlain : indexPath.length === 1 ? cn.tableRowPrimary : cn.tableRowSecondary,
          }),
        }}
      >
        <PageFrameTitlePortal model={store ?? undefined}></PageFrameTitlePortal>
        <div style={{ height: "100%", marginTop: "8px" }}>
          <Table headerClassName={cn.tableHeader} className={cn.table} />
        </div>
      </SimpleTableContext>
    );
  }

  return (
    <SimpleTableContext
      exportFileName=""
      columns={columns}
      data={store}
      tableOptions={{
        onRow: ({ indexPath, expand }) => ({
          className: expand === undefined ? cn.tableRowPlain : indexPath.length === 1 ? cn.tableRowPrimary : cn.tableRowSecondary,
        }),
      }}
    >
      <div className={cn.generic}>
        <ScalarInput />
        {model.isHaveTable && (
          <div className={cn.tableWrap}>
            <Typography.Title level={2}>Параметры по годам</Typography.Title>
            <div className={cn.simpleTable} style={{ "--line-count": `${lineCount}` } as React.CSSProperties}>
              <Table headerClassName={cn.tableHeader} className={cn.table} />
            </div>
          </div>
        )}
      </div>
    </SimpleTableContext>
  );
});

const GenericPageWrap: FC<{}> = () => <GenericPage />;

export { GenericPageWrap as GenericPage };
