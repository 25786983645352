import { FC, HTMLAttributes, PropsWithChildren } from "react";
import { observer } from "mobx-react-lite";

import { CustomLeaf, Tree } from "elements/tree/tree";
import { useProjectContext } from "models/project/context/projectContext";
import { useFact } from "models/project/fact/fact";
import { useForecast } from "models/project/fact/forecast/forecast";
import { TreeContextProvider } from "models/tree/context";
import { TreeNode } from "models/tree/tree";

const PRELOAD_HOOKS = [
  useFact,
  () => useFact()?.wellPads,
  () => useFact()?.stratums,
  () => useFact()?.producingObjects,
  () => useFact()?.licenseRegions,
  () => useFact()?.wells,
  useForecast,
  () => {
    const fc = useForecast();
    if (fc === null) {
      return { isLoading: false };
    } else {
      return fc?.wells;
    }
  },
];

type WellsTreeProps = {
  title: string;
  className?: string;
  customLeaf?: CustomLeaf;
  onRow?: (node?: TreeNode<any>) => Omit<HTMLAttributes<HTMLDivElement>, "style">;
};

const WellsTree: FC<WellsTreeProps & PropsWithChildren> = observer(({ title, className, customLeaf, onRow, children }) => {
  const projectContext = useProjectContext();
  const tree = projectContext.wellsTree;

  return (
    <TreeContextProvider value={tree}>
      <Tree title={title} className={className} customLeaf={customLeaf} onRow={onRow} preloadHooks={PRELOAD_HOOKS}>
        {children}
      </Tree>
    </TreeContextProvider>
  );
});

export { WellsTree };
