import { useMemo } from "react";
import { observer } from "mobx-react";

import { Tree } from "elements/tree/tree";
import { global } from "models/global";
import { useFact } from "models/project/fact/fact";
import { useForecast } from "models/project/fact/forecast/forecast";
import { Well } from "models/project/fact/well/well";
import { TreeContextProvider } from "models/tree/context";
import { TreeFilter } from "models/tree/filters/filters";
import { TreeRoot } from "models/tree/tree";

import { View } from "./view";

import cn from "./summary.module.less";

const PRELOAD_HOOKS = [
  useFact,
  () => useFact()?.wellPads,
  () => useFact()?.stratums,
  () => useFact()?.producingObjects,
  () => useFact()?.licenseRegions,
];

const NESTING_FIELDS = [
  {
    key: "mine",
    title: "Куст",
    getter: (w: Well) => w.pad,
  },
  {
    key: "licenseRegion",
    title: "Лицензионный участок",
    getter: (w: Well) => w.licenseRegion,
  },
  {
    key: "fond",
    title: "Фонд",
    getter: (w: Well) => (w.fond === "Base" ? "Базовый" : "Новый"),
  },
  {
    key: "producingObject",
    title: "Объект разработки",
    getter: (w: Well) => w.producingObject,
  },
];

const useFilters = (): TreeFilter<Well, any>[] => {
  console.assert(global.wellTypes.isLoading === false, "Попытка отображения сводки до завершения загрузки");
  const forecast = useForecast()!;

  return useMemo(
    () => [
      {
        title: "Фонд",
        options: ["Базовый", "Новый"],
        predicateFactory: (selected) => (well: Well) => selected.map((value) => (value === "Базовый" ? "Base" : "New")).includes(well.fond),
      },
      {
        title: "Лицензионные участки",
        options: forecast.licenseRegions.titles,
        predicateFactory: (selected) => (well: Well) => selected.includes(well.licenseRegion?.title ?? ""),
      },
      {
        title: "Назначение скважины",
        options: ["Добывающая", "Нагнетательная", "Нет истории работы", "Прочего назначения"],
        predicateFactory: (selected) => (well: Well) => {
          const status = forecast.production.wellStatus(well.id);
          if (selected.includes("Добывающая")) {
            if (status?.isMining) {
              return true;
            }
          }
          if (selected.includes("Нагнетательная")) {
            if (status?.isInjecting) {
              return true;
            }
          }
          if (selected.includes("Нет истории работы")) {
            if (status === undefined) {
              return true;
            }
          }
          if (selected.includes("Прочего назначения")) {
            return true;
          }
          return false;
        },
      },
    ],
    [forecast]
  );
};

const Summary = observer(() => {
  const forecast = useForecast()!;
  const filters = useFilters();

  const tree = useMemo(() => new TreeRoot("Все", forecast.wells.allWells, NESTING_FIELDS, filters), [forecast, filters]);
  tree.selectedLevels = [1, 2];
  return (
    <TreeContextProvider value={tree}>
      <div className={cn.root}>
        <Tree className={cn.tree} title="Скважины" preloadHooks={PRELOAD_HOOKS} />
        <View className={cn.view} tree={tree} />
      </div>
    </TreeContextProvider>
  );
});

export { Summary };
