import dayjs from "dayjs";

import { Lazy } from "models/lazy";
import { Params as ParamsModel } from "models/params/params";
import { ScenarioRaw } from "services/back/project";
import { depositsFactSource } from "services/finance/deposits";
import { investFactSource, operatingFactSource } from "services/finance/finance";
import { operatingRevenueFactSource } from "services/finance/operating/operating";
import { referenceFactSource } from "services/finance/reference";
import { nddFactSource, severanceFactSource, wealthFactSource } from "services/finance/tax/tax";
import { StorageKey } from "services/finance/utils";
import { DateRange } from "utils/dateRange";
import { Range } from "utils/range";

import { Stratums } from "../../project/stratum/stratums";
import { LicenseRegions } from "../licenseRegion/licenseRegions";
import { ProducingObjects } from "../producingObject/producingObjects";
import { Project, useProject } from "../project";

import { Forecasts } from "./forecast/forecasts";
import { Infrastructure } from "./infrastructure/infrastructure";
import { InvestParams } from "./investParams/investParams";
import { OperatingRevenue } from "./operatingParams/revenueTable";
import { OperatingParams } from "./operatingParams/years";
import { ProducingObjectsParams } from "./producingObjectsParams/producingObjectsParams";
import { Production } from "./production/production";
import { NDDTax } from "./tax/nddTax";
import { SeveranceTax } from "./tax/severanceTax";
import { WealthTax } from "./tax/wealthTax";
import { Wells } from "./well/wells";
import { WellPads } from "./wellPad/wellPads";
import { ECYStore } from "./ecy";
import { LicenseRegionsParamsMap } from "./licenseRegionParamsMap";
import { ReferenceParams } from "./referenceParams";

class Fact {
  public readonly investParams: LicenseRegionsParamsMap<InvestParams>;

  public readonly operatingParams: LicenseRegionsParamsMap<OperatingParams>;
  public readonly operatingRevenue: LicenseRegionsParamsMap<OperatingRevenue>;

  #severanceTax = new Lazy(() => new SeveranceTax(this.factRange, severanceFactSource({ fact: this.projectId })));
  public readonly wealthTax: LicenseRegionsParamsMap<WealthTax>;
  /* грязно захачено просто чтобы упростить код проверки загрузки. Можно переделать но некому.
  nddTax создаётся даже если участка с НДД нет но дальше весь код учитывает отсутствие участка с НДД */
  #nddTax = new Lazy(
    () => new NDDTax(new Range(2004, this.lastDescribedYear), nddFactSource({ fact: this.projectId }), this.project)
  );

  public readonly referenceParams: LicenseRegionsParamsMap<ReferenceParams>;

  #producingObjectsParams = new Lazy(() => new ProducingObjectsParams(this));
  #depositParams = new Lazy(
    () => new ParamsModel("Запасы", this.factRange, depositsFactSource({ fact: this.projectId }))
  );

  #production = new Lazy(() => new Production(this));

  #ecyParams = new Lazy(() => new ECYStore(this.wholeRange, this));

  #wells = new Lazy(() => new Wells(this));

  #wellPads = new Lazy(() => new WellPads());

  #infrastructure = new Lazy(() => new Infrastructure(this, null));

  public forecasts: Forecasts;

  constructor(public readonly id: number, scenarios: ScenarioRaw[], public readonly project: Project) {
    this.forecasts = new Forecasts(scenarios, this);

    this.wealthTax = new LicenseRegionsParamsMap(WealthTax, wealthFactSource, this);
    this.investParams = new LicenseRegionsParamsMap(InvestParams, investFactSource, this);
    this.referenceParams = new LicenseRegionsParamsMap(ReferenceParams, referenceFactSource, this);
    this.operatingRevenue = new LicenseRegionsParamsMap(OperatingRevenue, operatingRevenueFactSource, this);
    this.operatingParams = new LicenseRegionsParamsMap(OperatingParams, operatingFactSource, this);
  }

  public get projectId(): number {
    return this.project.id;
  }

  get storageKey(): StorageKey {
    return { fact: this.projectId };
  }

  static fromRawData(rawData: ScenarioRaw[], project: Project): Fact {
    const isFactTest = ({ isFactual }: ScenarioRaw) => isFactual;
    console.assert(rawData.filter(isFactTest).length === 1);
    const [fact] = rawData.splice(rawData.findIndex(isFactTest), 1);
    const forecast = [...rawData];
    return new Fact(
      fact.id,

      forecast,
      project
    );
  }

  public get wells(): Wells {
    return this.#wells.value;
  }

  public get ecyStore(): ECYStore {
    return this.#ecyParams.value;
  }

  public get licenseRegions(): LicenseRegions {
    return this.project.licenseRegions;
  }

  public get oilWays(): number[] {
    return this.project.oilWays;
  }

  public get stratums(): Stratums {
    return this.project.stratums;
  }

  public get producingObjects(): ProducingObjects {
    return this.project.producingObjects;
  }

  public get wellPads() {
    return this.#wellPads.value;
  }

  public get infrastructure() {
    return this.#infrastructure.value;
  }

  public get severanceTax(): SeveranceTax {
    return this.#severanceTax.value;
  }

  public get nddTax(): SeveranceTax {
    return this.#nddTax.value;
  }

  public get nddRange(): Range | null {
    return this.NDDYear ? new Range(this.NDDYear, this.lastDescribedYear) : null;
  }

  public get NDDYear(): number | null {
    const { nddRegion } = this.licenseRegions;
    return nddRegion?.ndd!.transitionYear ?? null;
  }

  public get producingObjectsParams(): ProducingObjectsParams {
    return this.#producingObjectsParams.value;
  }

  public get depositParams(): SeveranceTax {
    return this.#depositParams.value;
  }

  get investYear(): number {
    return this.project.startYear;
  }

  get production(): Production {
    return this.#production.value;
  }

  // год ожидания
  get lastDescribedYear(): number {
    return this.project.actualStateDate.year();
  }

  get factRange(): Range {
    return new Range(this.project.startYear, this.lastDescribedYear);
  }

  get forecastRange(): Range {
    return new Range(this.lastDescribedYear, this.project.lastForecastYear + 1);
  }

  get forecastDateRange(): DateRange {
    return new DateRange(this.project.actualStateDate.add(1, "month"), dayjs(`${this.project.lastForecastYear}-12-31`));
  }

  get wholeRange(): Range {
    return Range.join(this.factRange, this.forecastRange);
  }

  get isComplete(): boolean {
    return true;
  }
}

const useFact: () => Fact | undefined | null = () => {
  const project = useProject();
  if (project === null || project === undefined) {
    return project;
  }
  return project.fact;
};

export { Fact, Fact as State, useFact };
