import { FC, ReactNode } from "react";
import { Divider, Form } from "antd";
import locale from "antd/es/date-picker/locale/ru_RU";
import dayjs from "dayjs";
import { observer } from "mobx-react";

import { InputNumber } from "elements/inputs/inputNumber/inputNumber";
import { MonthPicker } from "elements/inputs/monthPicker";
import { useInfrastructure } from "features/infrastructure/useInfrastructure";
import { NodeType } from "models/project/fact/infrastructure/nodes";

import { CoordParams } from "../../../ui/coordParams";
import { FieldParams } from "../../../ui/fieldParams";

import cn from "../selectedItem.module.less";
import localStyles from "./technologyParams.module.less";

const NodeWrapper: FC<{ children: ReactNode }> = ({ children }) => <div className={localStyles.mineParamsWrap}>{children}</div>;

type TechnologyParamsProps = {
  selectedItem: NodeType;
};

const TechnologyParams: FC<TechnologyParamsProps> = observer(({ selectedItem }) => {
  const { nodes, range } = useInfrastructure();

  const handleUpdate = (node: Partial<NodeType>) => {
    nodes.update({ uuid: selectedItem.uuid, ...node });
  };

  if (selectedItem.type === "mine") {
    return (
      <div className={cn.tehnologyWrapper}>
        <FieldParams title="Дата ввода" className={localStyles.startedAt}>
          {dayjs(selectedItem.startedAt).format("MM.YYYY")}
        </FieldParams>
        <NodeWrapper>
          <CoordParams coordinates={{ x: selectedItem.x, y: selectedItem.y }} />
          <FieldParams title="Альтитуда" className={localStyles.startedAt}>
            <InputNumber
              bordered
              placeholder="266..."
              value={selectedItem.altitude}
              onUpdate={(value) => handleUpdate({ altitude: value ?? undefined })}
            />
          </FieldParams>
        </NodeWrapper>
      </div>
    );
  }

  return (
    <div className={cn.tehnologyWrapper}>
      <Form.Item name="startedAt" label="Дата ввода" className={localStyles.paddingNone}>
        <MonthPicker
          className={cn.date}
          placeholder="Выбрать..."
          locale={locale}
          onChange={(date) => handleUpdate({ startedAt: date })}
          start={dayjs(range.from.toString())}
          end={dayjs(range.to.toString())}
        />
      </Form.Item>
      <Form.Item name="finishedAt" label="Дата выбытия">
        <MonthPicker
          className={cn.date}
          placeholder="Выбрать..."
          locale={locale}
          onChange={(date) => handleUpdate({ finishedAt: date })}
          start={selectedItem.startedAt}
          end={dayjs(range.to.toString())}
        />
      </Form.Item>
      <Form.Item name="altitude" label="Альтитуда">
        <InputNumber bordered placeholder="266..." onUpdate={(altitude) => handleUpdate({ altitude: altitude ?? undefined })} />
      </Form.Item>
      <NodeWrapper>
        <Divider className={cn.divider} />
        <FieldParams title="Мощность">{selectedItem.power} тыс м3/сут</FieldParams>
        <FieldParams title="Категория">{selectedItem.category ?? "Новый"}</FieldParams>
        <CoordParams coordinates={{ x: selectedItem.x, y: selectedItem.y }} />
        <Divider className={cn.divider} />
      </NodeWrapper>
    </div>
  );
});

export { TechnologyParams };
